<template>
  <time-picker
    class="ct-time-picker"
    :class="{compact: compact}"
    v-on="$listeners"
    v-bind="$attrs"
    :readonly="readonly"
    :disabled="disabled"
    :prefix-icon="prefixIcon">
  </time-picker>
</template>
<script>
import TimePicker from 'element-ui/lib/time-picker'
import '../../themes/element-ui/time-picker.css'
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'
locale.use(lang)

export default {
  name: 'CtTimePicker',
  components: {
    TimePicker
  },
  props: {
    prefixIcon: {
      type: String,
      default: 'mdi mdi-clock'
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    compact: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
  .ct-time-picker {
    border-radius: 4px;
  }

  .ct-time-picker >>> .el-input__inner,.ct-time-picker.el-input__inner {
    color: #434761;
    background-color: #ECEDF2;
    border: none;
    border-bottom: 2px solid #ECEDF2;
  }
  .ct-time-picker >>> .el-input__inner:focus,.ct-time-picker.el-input__inner:focus-within {
    border-bottom-color: #126BFF;
  }
  .ct-time-picker >>> .el-input__prefix,.ct-time-picker >>>  .el-range__icon {
    color: #434761;
  }
  .ct-time-picker >>> span.el-input__prefix {
    left: 10px;
  }
  .ct-time-picker >>> .el-input__inner {
    padding-left: 32px;
    padding-right: 24px;
    margin-top: 2px;
  }
  .ct-time-picker >>> .el-input__prefix,
  .ct-time-picker >>> .el-range__icon {
    color: #63698F!important;
    font-size: 16px;
  }
</style>
<style lang="sass" scoped>
.ct-time-picker
  &.compact
    ::v-deep .el-input__inner
      height: 24px
      background-color: rgb(238, 244, 255)
      border-color: rgb(238, 244, 255)
      font-size: 12px
    ::v-deep .el-input__icon
      line-height: 28px
    &.el-input
      width: 92px
</style>
